import { withAuthenticationRequired } from '@auth0/auth0-react'
import Layout from 'manage-tritag/components/layout'
import CreateForm from 'manage-tritag/components/pages/teams/create-form'

const CreateTeamPage = () => {
  return (
    <Layout label1="Teams" label2="Create" url="/teams">
      <CreateForm />
    </Layout>
  )
}

export default withAuthenticationRequired(CreateTeamPage)
