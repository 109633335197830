import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
  Box,
  Select,
} from '@mui/material'
import { navigate } from 'gatsby'
import { toast } from 'react-toastify'
import { Controller, useForm } from 'react-hook-form'
import { useAddTeamMutation } from 'manage-tritag/services/api/endpoints/teams'
import { useGetAssociationsQuery } from 'manage-tritag/services/api/endpoints/associations'
import { noLimitParams } from 'manage-tritag/utils/string'
import Spinner from 'manage-tritag/components/loading/spinner'
import { useMediaQuery } from 'react-responsive'
import {
  FormContent,
  StyledButton,
  StyledButtonDiv,
  StyledGridContainer,
  StyledTextField,
} from './styles'

const DivisionCreateForm = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 898px)` })
  const [createTeam, { isLoading: createLoading }] = useAddTeamMutation()
  const { data: associations, isLoading } =
    useGetAssociationsQuery(noLimitParams)
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      name: '',
      association: '',
      contactName: '',
      contactEmail: '',
      contactNumber: '',
    },
  })

  const onSubmit = async (createData: any) => {
    if (createLoading) return
    await createTeam(createData).unwrap()
    reset()
    toast.success('Team added succesfully.')
    navigate('/teams')
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <StyledGridContainer container justifyContent="space-between">
        <Grid item xs={12} sm={12} md={12}>
          <Typography sx={{ fontWeight: 'bold', color: '#008174' }}>
            Team details
          </Typography>

          <FormContent>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 1 }}
                  id="filled-basic"
                  label="Team Name"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />

            <FormControl variant="filled" size="small" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">
                Association
              </InputLabel>
              <Controller
                name="association"
                control={control}
                render={({ field }) => (
                  <Select
                    inputProps={{ tabIndex: 2 }}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    disableUnderline
                    {...field}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {!isLoading &&
                      associations &&
                      associations.map(association => (
                        <MenuItem key={association._id} value={association._id}>
                          {association.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>

            <Controller
              name="contactName"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 3 }}
                  id="filled-basic"
                  label="Primary contact"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />
          </FormContent>

          <FormContent>
            <Controller
              name="contactEmail"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 4 }}
                  id="filled-basic"
                  label="Primary contact email"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />

            <Controller
              name="contactNumber"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 5 }}
                  id="filled-basic"
                  label="Primary contact number"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />

            {!isMobile && <Box sx={{ width: '100%' }} />}
          </FormContent>
        </Grid>
      </StyledGridContainer>
      <StyledButtonDiv>
        <div />

        <div>
          <StyledButton
            variant="outlined"
            onClick={() => {
              navigate('/teams')
            }}
            sx={{
              border: '2px solid #008174',
              color: '#000',
              marginRight: '1.5rem',
            }}
          >
            CANCEL
          </StyledButton>
          <StyledButton
            variant="contained"
            color="primary"
            type="submit"
            sx={{ width: '83px' }}
          >
            {createLoading ? <Spinner size={22} /> : 'SAVE'}
          </StyledButton>
        </div>
      </StyledButtonDiv>
    </Box>
  )
}

export default DivisionCreateForm
